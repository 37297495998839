import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_URL_STAGING
      : "https://" + window.location.host + "/backend/public/api/v1",
});

export default api;
